import * as React from 'react'
import Helmet from 'react-helmet'

import { About } from '../components/About'
import { Footer } from '../components/Footer'
import AboutLayout from '../layouts'

const AboutPage: React.FC = () => {
  return (
    <AboutLayout>
      <Helmet meta={[{ property: 'og:url', content: 'https://www.codehop.net/about' }]} />
      <About />
      <Footer />
    </AboutLayout>
  )
}

export default AboutPage
