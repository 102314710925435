import * as React from 'react'

import { Container } from '../Container'
import { Col } from '../Col'
import { FullScreenSection } from '../FullScreenSection'

export const About = () => (
    <FullScreenSection>
    <Container>
	<h1>About this site</h1>
	<p>
		<cite><a target="_blank" href="https://www.oed.com/view/Entry/85813162" rel="noopener noreferrer">
		From OED Online (September 2020):</a></cite>
	</p>
	<blockquote>
		<h3>code-hop, <i>v.</i></h3>
		<p>Originally <i>Australian</i> and <i>New Zealand</i>.</p>
		<p><i>intransitive</i>. Of a sportsperson: to switch from playing one form of football to another, esp. from rugby union to rugby league or vice versa.</p>
	</blockquote>
	<p>
		Ah, choosing a domain name! I first thought up a lot of cool-sounding combinations like a normal person: things like nimb.us or aeol.us. Those and many others were taken.
	</p>
	<p>
		Those words have been around a long, long time and so has the Internet. So then I thought, "why not try out some new words, words that haven't been around so long?"
		I found <a target="_blank" href="https://public.oed.com/updates/new-words-list-september-2020/"><i>this</i></a> resource, and the rest is history.
	</p>
    </Container>
    </FullScreenSection>
)
